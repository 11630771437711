import React from "react";
import * as Chakra from "@chakra-ui/react";
import Backdrop from "../backdrop";
import { Link } from "gatsby";
import Arrow from "../../images/arrow_side.svg";

const HeaderResponsiveMenu = ({ isOpen, onClose, menu, ...props }) => {
  return (
    <>
      <Backdrop onClose={onClose} isOpen={isOpen} />
        <Chakra.Flex
            display={{ base: "flex", xl: "none" }}
            p="17px 50px 0 18px"
            boxShadow="base"
            zIndex="500"
            direction="column"
            bg="white"
            left="0"
            transform={isOpen ? "translateX(0)" : `translateX(-100%)`}
            transition="transform .3s"
            pos={isOpen ? "absolute" : "absolute"}
            height="calc(100vh - 95px)"
            overflowY="scroll"
            width={{ base: "100%", md: "unset" }}
            top={{ base: "95px", xl: "141px" }}
            {...props}
        >
            {menu.map((item, i) => (
                <Chakra.Flex mb="38px" direction="column" key={i}>
                    <Chakra.Flex
                        alignSelf="flex-start"
                        mb="25px"
                        borderBottom="5px solid rgba(82, 176, 217, 1)"
                        pb="5px"
                        textTransform="uppercase"
                        fontWeight="600"
                        as={item.href ? Link : undefined}
                        to={item.href ? item.href : undefined}
                        onClick={item.href ? onClose : undefined}
                    >
                        {item.menu}
                    </Chakra.Flex>
                    {item.submenu &&
                    item?.submenu[0]?.href &&
                    !item?.submenu[0]?.rooms &&
                    item.submenu.map((item, i) => (
                        <Chakra.Link
                            _hover={{
                                textDecor: "underline",
                            }}
                            onClick={() => onClose()}
                            to={item.href}
                            as={Link}
                            key={i}
                            pl="23px"
                            _notLast={{ mb: "24px" }}
                        >
                            {item.title}
                        </Chakra.Link>
                    ))}
                    {item.submenu &&
                    item?.submenu[0]?.rooms &&
                    item?.submenu[0]?.rooms?.length > 0 &&
                    item.submenu.map((item, i) => (
                        <Chakra.Box
                            // borderTopWidth="5px"
                            // borderColor={item?.color}
                            // width="100%"
                            key={i}
                            _notLast={{ pb: "30px" }}
                            // mb="20px"
                        >
                            <Chakra.Text
                                onClick={() => onClose()}
                                pl="23px"
                                fontWeight="600"
                                mb="20px"
                            >
                                {item.title}
                            </Chakra.Text>
                            {item.rooms.map((room, i) => {
                                return (
                                    <Chakra.Flex
                                        as={Link}
                                        _hover={{
                                            textDecor: "underline",
                                        }}
                                        key={i}
                                        to={room.href}
                                        pl="46px"
                                        _notLast={{ pb: "10px" }}
                                        textTransform="capitalize"
                                        alignItems="center"
                                        onClick={() => onClose()}
                                    >
                                        <Chakra.Text>
                                            {room.title} ({room.count}){" "}
                                        </Chakra.Text>
                                        <Chakra.Box
                                            as={Arrow}
                                            marginLeft="14px"
                                            color={item.color}
                                        />
                                    </Chakra.Flex>
                                );
                            })}
                        </Chakra.Box>
                    ))}
                </Chakra.Flex>
            ))}
        </Chakra.Flex>
    </>
  );
};

export default HeaderResponsiveMenu;
