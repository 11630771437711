import React from "react";

function XingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.5"
      height="31.5"
      viewBox="0 0 31.5 31.5"
    >
      <path
        fill="#fff"
        d="M28.125 2.25H3.375A3.376 3.376 0 000 5.625v24.75a3.376 3.376 0 003.375 3.375h24.75a3.376 3.376 0 003.375-3.375V5.625a3.376 3.376 0 00-3.375-3.375zM9.872 22.514H6.6a.473.473 0 01-.422-.724l3.466-6.1c.007 0 .007-.007 0-.014l-2.208-3.8a.448.448 0 01.422-.71h3.272a1.047 1.047 0 01.907.612l2.243 3.888-3.523 6.2a1.034 1.034 0 01-.885.648zM25.32 7.46l-7.229 12.706v.014l4.609 8.367a.451.451 0 01-.422.71H19a1.008 1.008 0 01-.907-.612l-4.641-8.459q.243-.432 7.27-12.818a1.009 1.009 0 01.879-.612h3.3a.449.449 0 01.422.7z"
        data-name="XingIcon awesome-xing-square"
        transform="translate(0 -2.25)"
      ></path>
    </svg>
  );
}

export default XingIcon;
