import React from "react";

function HamburgerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.106 22.745"
      {...props}
    >
      <path
        fill="#52b0d9"
        d="M0 0v3.263h26.106V0zm0 9.692v3.263h26.106V9.692zm0 9.79v3.263h26.106v-3.263z"
        data-name="Icon open-menu"
      ></path>
    </svg>
  );
}

export default HamburgerIcon;
