import * as Chakra from "@chakra-ui/react";
import React from "react";
import { Link as GatsbyLink } from "gatsby"


const CustomLink = (props) => {

    return (
        <Chakra.Box
            as={GatsbyLink}
            fontWeight="300"
            fontSize={{ base: "16px", xl: "22px" }}
            color="#00ABDA"
            display="block"
            width="fit-content"
            css={{
                transition: "all 0.3s",
                position: "relative",
                "&:before": {
                    content: '""',
                    position: "absolute",
                    width: "100%",
                    transform: "scale(0)",
                    height: "1px",
                    bottom: "0",
                    right: "0",
                    // top: "20px",
                    backgroundColor: "#00ABDA",
                    transformOrigin: "bottom right ",
                    transition: "transform 0.3s ease-out",
                },
                "&:hover:before": {
                    transform: "scale(1)",
                    transformOrigin: "bottom left",
                },
                "&:hover": {
                    color: "#00ABDA",
                },
            }}
            {...props}
        />
    )

}

export default CustomLink
