import React from "react";
import * as Chakra from "@chakra-ui/react";
import { Link } from "gatsby";
import Arrow from "../../images/arrow_side.svg";
import CustomLink from "../custom-link";
import { useState } from "react";

const HeaderLink = ({
  submenu,
  href,
  children,
  position,
  menu,
  isExternal = false,
  ...props
}) => {
  const [allowOpen, setAllowOpen] = useState(true);
  return (
    <Chakra.Flex
      role="group"
      pos={menu === "Unsere Stahlmöbel" ? "" : "relative"}
      {...props}
    >
      <Chakra.Link
        _groupHover={
          allowOpen && {
            textDecor: "none",
            fontWeight: "600",
            borderBottom: "5px solid rgba(82, 176, 217, 1)",
          }
        }
        onMouseEnter={() => setAllowOpen(true)}
        pb="5px"
        borderBottom="5px solid rgba(82, 176, 217, 0)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        as={href && !isExternal ? Link : undefined}
        to={href ? href : undefined}
        href={isExternal ? href : undefined}
        isExternal
      >
        {children}
      </Chakra.Link>
      {submenu && submenu[0]?.href && !submenu[0]?.rooms && (
        <Chakra.Flex
          _groupHover={
            allowOpen && {
              opacity: 1,
              transform: "none",
            }
          }
          opacity="0"
          transform="translateY(-100vh)"
          zIndex={"51"}
          top={{ base: "75px", xl: "43px" }}
          right={position === "left" ? "0" : "auto"}
          w="381px"
          bg="white"
          transition="opacity 0.3s, transform 0s linear .3s"
          boxShadow="base"
          py="40px"
          pos="absolute"
          direction="column"
        >
          {submenu &&
            submenu.map((item, i) => (
              <CustomLink
                key={i}
                to={item.href}
                ml="38px"
                _notLast={{ mb: "30px" }}
                textTransform="capitalize"
                fontSize="16px"
                color="#000"
                onClick={() => setAllowOpen(false)}
              >
                {item.title}
              </CustomLink>
            ))}
        </Chakra.Flex>
      )}
      {submenu && submenu[0]?.rooms?.length > 0 && !submenu[0]?.href && (
        <Chakra.Flex
          _groupHover={
            allowOpen && {
              opacity: 1,
              transform: "none",
            }
          }
          opacity="0"
          transform="translateY(-100vh)"
          zIndex={"51"}
          top={{ base: "75px", xl: "141px" }}
          right="0"
          left="0"
          width="100%"
          minWidth="100%"
          bg="white"
          transition="opacity 0.3s, transform 0s linear .3s"
          boxShadow="base"
          py="40px"
          pos="absolute"
          direction="column"
        >
          <Chakra.Box width="100%" maxWidth="1224px" margin="auto">
            <Chakra.Flex
              width="100%"
              maxWidth="866px"
              justifyContent="space-between"
              maxH="650px"
              margin="auto"
              direction="column"
              overflowY="auto"
              flexWrap="wrap"
              columnGap="20px"
            >
              {submenu.map((item, i) => (
                <Chakra.Flex
                  width="100%"
                  maxW="350px"
                  direction="column"
                  key={i}
                  _notLast={{ pb: "32px" }}
                  flexWrap="nowrap"
                >
                  <Chakra.Box
                    borderTopWidth="5px"
                    borderColor={item?.color}
                    width="100%"
                  >
                    <Chakra.Flex
                      pt="14px"
                      pb="34px"
                      textTransform="capitalize"
                      fontWeight="600"
                    >
                      <Chakra.Link
                        as={Link}
                        to={`/filterseite/produktlinien/${item?.slug}`}
                        onClick={() => setAllowOpen(false)}
                      >
                        {item.title}
                      </Chakra.Link>
                    </Chakra.Flex>
                    {item.rooms.map((room, i) => {
                      if (room.count === 0) return null;
                      return (
                        <Chakra.Box width="100%" _notLast={{ pb: "15px" }}>
                          <CustomLink
                            key={i}
                            to={room.href}
                            fontSize="16px"
                            color="#000"
                            textTransform="capitalize"
                            alignItems="center"
                            display="inline-flex"
                            onClick={() => setAllowOpen(false)}
                          >
                            <Chakra.Text>
                              {room.title} ({room.count}){" "}
                            </Chakra.Text>
                            <Chakra.Box
                              as={Arrow}
                              marginLeft="14px"
                              color="#888"
                            />
                          </CustomLink>
                        </Chakra.Box>
                      );
                    })}
                  </Chakra.Box>
                </Chakra.Flex>
              ))}
            </Chakra.Flex>
          </Chakra.Box>
        </Chakra.Flex>
      )}
    </Chakra.Flex>
  );
};

export default HeaderLink;
