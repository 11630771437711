import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 184.68 225.98"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h184.68v225.98H0z"
        />
      </clipPath>
      <clipPath id="b">
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h184.68v225.98H0z"
        />
      </clipPath>
      <clipPath id="c">
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h184.68v225.98H0z"
        />
      </clipPath>
      <clipPath id="d">
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h184.68v225.98H0z"
        />
      </clipPath>
      <clipPath id="e">
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h184.68v225.98H0z"
        />
      </clipPath>
    </defs>
    <g
      style={{
        clipPath: "url(#a)",
      }}
    >
      <path
        d="m162.07 120.31-.56.23c-21.21 8.87-44.5 13.76-68.91 13.76s-47.9-4.94-69.18-13.89l-.28-.11-20.26 35.12h179.46l-20.27-35.11Zm-78.12-8.8c-13.19-.95-25.8-4.02-37.46-8.9l46.12-79.88 46.12 79.89a119.405 119.405 0 0 1-37.46 8.9v12.15c19.59-.99 38.28-5.33 55.55-12.47L92.61 0l-64.2 111.2c17.26 7.13 35.95 11.47 55.54 12.47v-12.15Z"
        style={{
          fill: "#cc1a20",
        }}
      />
    </g>
    <path
      style={{
        fill: "#be1622",
      }}
      d="M53.67 173.67v9.94h17.38v5.57H53.67v10.15h20.11v5.58H47.47v-36.82h25.68v5.58H53.67zM95.7 173.67v31.24h-6.21v-31.24H77.87v-5.58h29.5v5.58H95.7zM124.53 174.72l-6.94 16.36h13.89l-6.94-16.36Zm-9.26 21.88-3.52 8.31h-6.46l16.25-36.81h6.36l16.09 36.81h-6.63l-3.53-8.31h-18.56ZM176.86 168.09v27.16l-20.02-27.16h-6.1v36.82h6.2v-26.93l19.92 26.93h6.11v-36.82h-6.11zM32.47 168.09l-13.18 21.89-13.22-21.89H.09v36.82h5.85V178.4l11.29 18.16h4.1l11.28-18.16.06 26.51h5.73v-36.82h-5.93z"
    />
    <g
      style={{
        clipPath: "url(#b)",
      }}
    >
      <path
        d="m7.38 217.28-.67 1.42c-1.04-.63-2.07-.89-2.8-.89-.94 0-1.55.35-1.55 1 0 2.09 5.16.97 5.14 4.4 0 1.71-1.5 2.76-3.6 2.76-1.5 0-2.92-.62-3.9-1.52l.7-1.39c.98.9 2.21 1.39 3.23 1.39 1.12 0 1.79-.42 1.79-1.16 0-2.13-5.15-.94-5.15-4.34 0-1.64 1.4-2.66 3.47-2.66 1.24 0 2.46.39 3.35.98"
        style={{
          fill: "#213c47",
        }}
      />
    </g>
    <path
      style={{
        fill: "#213c47",
      }}
      d="M9.62 216.36v1.45h3.01v8.1h1.62v-8.1h3.03v-1.45H9.62zM22.83 218.08l1.8 4.24h-3.6l1.8-4.24Zm-.78-1.72-4.21 9.55h1.67l.91-2.15h4.82l.91 2.15h1.71l-4.17-9.55h-1.65ZM37.18 216.36v4.15h-5.06v-4.15h-1.61v9.55h1.61v-3.95h5.06v3.95h1.61v-9.55h-1.61zM42.67 216.36v9.55h5.74v-1.46h-4.14v-8.09h-1.6zM59.52 216.36l-3.14 6.35-3.15-6.35h-1.92v9.55h1.52v-7.13l3.02 6.13h1.05l3.01-6.13.02 7.13h1.48v-9.55h-1.89z"
    />
    <g
      style={{
        clipPath: "url(#c)",
      }}
    >
      <path
        d="M71.54 214.22c0 .49-.34.86-.82.86s-.83-.37-.83-.86.35-.87.83-.87.82.37.82.87m-2.42 0c0 .49-.34.86-.82.86s-.83-.37-.83-.86.35-.87.83-.87.82.37.82.87m-2.98 6.92c0 1.9 1.57 3.4 3.44 3.4s3.4-1.5 3.4-3.4-1.53-3.37-3.4-3.37-3.44 1.48-3.44 3.37m8.49 0c0 2.73-2.21 4.84-5.06 4.84s-5.06-2.11-5.06-4.84 2.21-4.81 5.06-4.81 5.06 2.09 5.06 4.81M79.33 224.48h2.46c1.26 0 2.01-.49 2.01-1.39 0-.85-.75-1.35-2.01-1.35h-2.46v2.75Zm0-4.16h2.46c1.03 0 1.64-.48 1.64-1.28s-.61-1.24-1.64-1.24h-2.46v2.52Zm2.54-3.96c1.98 0 3.21.9 3.21 2.39 0 1.07-.64 1.88-1.68 2.13 1.28.22 2.05 1.13 2.05 2.42 0 1.63-1.33 2.62-3.48 2.62h-4.24v-9.55h4.15Z"
        style={{
          fill: "#213c47",
        }}
      />
    </g>
    <path
      style={{
        fill: "#213c47",
      }}
      d="M88.72 216.36v9.55h6.98v-1.44h-5.37v-2.64h4.66v-1.44h-4.66v-2.58h5.21v-1.45h-6.82zM99.03 216.36v9.55h5.75v-1.46h-4.14v-8.09h-1.61z"
    />
    <g
      style={{
        clipPath: "url(#d)",
      }}
    >
      <path
        d="m119.05 217.28-.67 1.42c-1.04-.63-2.07-.89-2.8-.89-.94 0-1.55.35-1.55 1 0 2.09 5.16.97 5.14 4.4 0 1.71-1.5 2.76-3.6 2.76-1.5 0-2.92-.62-3.9-1.52l.7-1.39c.98.9 2.21 1.39 3.23 1.39 1.12 0 1.79-.42 1.79-1.16 0-2.13-5.16-.94-5.16-4.34 0-1.64 1.41-2.66 3.48-2.66 1.24 0 2.46.39 3.34.98"
        style={{
          fill: "#213c47",
        }}
      />
    </g>
    <path
      style={{
        fill: "#213c47",
      }}
      d="M122.42 216.36v9.55h6.98v-1.44h-5.37v-2.64h4.67v-1.44h-4.67v-2.58h5.21v-1.45h-6.82zM132.73 216.36h1.61v9.55h-1.61zM137.08 216.36v1.45h3.02v8.1h1.61v-8.1h3.03v-1.45h-7.66zM151.29 216.36v1.44h1.87v8.11h1.61v-9.55h-3.48z"
    />
    <g
      style={{
        clipPath: "url(#e)",
      }}
    >
      <path
        d="M163.75 219.46c.01-.83-.6-1.77-1.98-1.77-1.16 0-1.94.67-1.94 1.83 0 1.09.71 1.76 1.87 1.76 1.29 0 2.06-.83 2.04-1.81m-5.14 5.8.63-1.21c.58.38 1.39.6 2.11.6 1.86 0 2.72-1.39 2.66-3.45-.5.89-1.4 1.35-2.6 1.35-1.91 0-3.07-1.19-3.07-3.04 0-1.98 1.32-3.21 3.36-3.21 2.41 0 3.89 1.72 3.89 4.66s-1.6 5.03-4.13 5.03c-.94 0-2.07-.28-2.85-.72M170 222.82c-.01.83.6 1.77 1.98 1.77 1.16 0 1.94-.67 1.94-1.83 0-1.09-.71-1.76-1.87-1.76-1.29 0-2.06.83-2.04 1.81m5.14-5.8-.63 1.22c-.58-.38-1.39-.6-2.11-.6-1.86 0-2.72 1.39-2.66 3.45.5-.89 1.4-1.35 2.6-1.35 1.91 0 3.07 1.19 3.07 3.04 0 1.98-1.32 3.21-3.35 3.21-2.42 0-3.89-1.72-3.89-4.65s1.6-5.03 4.12-5.03c.94 0 2.08.29 2.85.72M184.24 218.7c0 .97-.73 2.05-1.75 3.14l-2.48 2.63h4.66v1.45h-6.79v-1.17l3.52-3.78c.7-.71 1.16-1.49 1.16-2.03 0-.76-.6-1.23-1.6-1.23-.84 0-1.8.47-2.61 1.2l-.64-1.25c1.03-.84 2.25-1.36 3.47-1.36 1.81 0 3.05.94 3.05 2.4"
        style={{
          fill: "#213c47",
        }}
      />
    </g>
  </svg>
);

export default SvgComponent;