import React from "react";

function LogoSmall() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.767 64.23">
      <g data-name="Group 245">
        <path
          fill="#b7041c"
          d="M44.112 64.23l-1.132-2.6h-5.966l-1.132 2.6H33.8l5.222-11.54h2.048l5.172 11.54zm-6.355-4.336h4.463l-2.231-5.127zm19.049 4.336l-6.4-8.439v8.439H48.41V52.69h1.96l6.436 8.51v-8.51h1.961v11.54zm-28.079 0v-9.793h-3.734V52.69h9.481v1.747h-3.752v9.793zm-13.5 0V52.69h8.254v1.747h-6.262v3.116H22.8V59.3h-5.581v3.182h6.461v1.748zm-4.756 0l-.017-8.31-3.628 5.693H5.509L1.876 55.92v8.31H0V52.69h1.921l4.246 6.86 4.239-6.86h1.9v11.54zM.9 48.716l6.513-11 .087.036A58.54 58.54 0 0029.73 42.1a58.534 58.534 0 0022.142-4.314l.18-.073 6.513 11zm31.612-13.763a39.108 39.108 0 0012.04-2.788L29.731 7.124V0l20.631 34.857a54.988 54.988 0 01-17.85 3.906zM9.1 34.856L29.731 0v7.124L14.91 32.165a39.1 39.1 0 0012.039 2.788v3.81A54.984 54.984 0 019.1 34.856z"
          data-name="Union 1"
        ></path>
      </g>
    </svg>
  );
}

export default LogoSmall;
