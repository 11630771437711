import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  "2xl": "1450px",
};

const theme = extendTheme({
  fonts: {
    heading:
      'Saira, system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif',
    body:
      'Saira, system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif',
  },
  colors: {
    red: "#941718",
    darkRed: "#B70A1C",
    camelot: "#803848",
    purple: "#714265",
    sorbus: "#EC662D",
    orange: "#FC9D00",
    yellow: "#FFCC2B",
    lime: "#90B626",
    citrus: "#C6C432",
    cyan: "#00ABDA",
    grey: {
      100: "#F2F2F2",
      800: "#3E4348",
      900: "#161615",
    },
    glacier: "#6EBFC2",
    white: "#fff",
    black: "#000",
    mint: "#6EBFC2",
    tangaroa: "#12273D",
    bone: "#F5F2EF",
  },
  breakpoints,
});

export default theme;
