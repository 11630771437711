import React from "react";
import * as Chakra from "@chakra-ui/react";
import Heading from "../heading";
import FacebookIcon from "../icons/facebook-icon";
import XingIcon from "../icons/xing-icon";
import LinkedinIcon from "../icons/linkedin-icon";
import { useStaticQuery, graphql, Link } from "gatsby";
import fontScale from "../../utils/font-scale";
import CustomLink from "../custom-link";

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        siteSetting {
          data {
            attributes {
              company_legal_name
              company_links {
                id
                text
                url
              }
              email
              facebook_url
              fax
              linkedin_url
              other_links {
                text
                url
              }
              phone
              product_links {
                text
                url
              }
              province
              street_address
              xing_url
            }
          }
        }
      }
    }
  `);

  let base = data?.strapi?.siteSetting?.data?.attributes;

  return (
    <Chakra.Flex
      py={{ base: "30px", xl: "68px" }}
      color="white"
      px="40px"
      bg="black"
      flexDirection="column"
    >
      <Chakra.Stack
        maxW="1373px"
        w="100%"
        m="auto"
        direction={{ base: "column", xl: "row" }}
      >
        <Chakra.Box w="100%" pb={{ base: "16px", xl: "0" }}>
          <Heading
            mb="16px"
            fontSize={fontScale("22px")}
            color="rgba(255,255,255,0.87)"
            textTransform="uppercase"
          >
            Kontakt
          </Heading>
          <Chakra.Text>
            <Chakra.Text as="strong">{base?.company_legal_name}</Chakra.Text>
            <br />
            {base?.street_address}
            <br />
            {base?.province}
            <br />
            Tel: {base?.phone}
            <br />
            Fax: {base?.fax}
            <br />
            {base?.email}
          </Chakra.Text>
        </Chakra.Box>
        <Chakra.Grid
          templateRows={{ base: "repeat(3, auto)", xl: "repeat(2, auto)" }}
          templateColumns={{ base: "repeat(1 auto)", md: "repeat(2, auto)", xl: "repeat(5, 1fr)" }}
          rowGap={{ base: "28px", xl: "50px" }}
          columnGap={{ sm: "0", lg: "93px", xl: "100px" }}
        >
          <Chakra.GridItem >
            <Heading
              mb="16px"
              fontSize={fontScale("22px")}
              color="rgba(255,255,255,0.87)"
              textTransform="uppercase"
            >
              Produktlinien
            </Heading>
            <Chakra.Stack direction="column" spacing="10px">
              {base?.product_links.map((item, i) => (
                <CustomLink fontSize="16px" color="#fff" to={item.url} as={Link} key={i}>
                  {item.text}
                </CustomLink>
              ))}
            </Chakra.Stack>
          </Chakra.GridItem>
          <Chakra.GridItem
            d="flex"
            justifyContent={{ base: "flex-end", sm: "flex-start" }}
          >
            <Chakra.Box maxW="167px">
              <Heading
                mb="16px"
                fontSize={fontScale("22px")}
                color="rgba(255,255,255,0.87)"
                textTransform="uppercase"
              >
                Unternehmen
              </Heading>
              <Chakra.Stack direction="column" spacing="10px">
                {base?.company_links.map((item, i) => (
                  <CustomLink fontSize="16px" color="#fff" to={item.url} as={Link} key={i}>
                    {item.text}
                  </CustomLink>
                ))}
              </Chakra.Stack>
            </Chakra.Box>
          </Chakra.GridItem>
          <Chakra.GridItem display={{ base: "grid-item", xl: "none" }}>
            <Chakra.Stack direction="column" spacing="28px">
              <a href={base?.facebook_url}>
                <FacebookIcon />
              </a>
              <a href={base?.xing_url}>
                <XingIcon />
              </a>
              <a href={base?.linkedin_url}>
                <LinkedinIcon />
              </a>
            </Chakra.Stack>
          </Chakra.GridItem>
          <Chakra.GridItem
            d="flex"
            pr={{ base: "71px", sm: "0" }}
            justifyContent={{ base: "flex-end", sm: "flex-start" }}
          >
            <Chakra.Box maxW="93px">
              <Chakra.Stack mt="42px" direction="column" spacing="10px">
                {base?.other_links.map((item, i) => (
                  <CustomLink fontSize="16px" color="#fff" to={item.url} as={Link} key={i}>
                    {item.text}
                  </CustomLink>
                ))}
              </Chakra.Stack>
            </Chakra.Box>
          </Chakra.GridItem>
        </Chakra.Grid>

        <Chakra.Box display={{ base: "none", xl: "block" }}>
          <Chakra.Stack direction="column" spacing="28px">
            {base?.facebook_url && (
              <a href={base?.facebook_url}>
                <FacebookIcon />
              </a>
            )}
            {base?.xing_url && (
              <a href={base?.xing_url}>
                <XingIcon />
              </a>
            )}
            {base?.linkedin_url && (
              <a href={base?.linkedin_url}>
                <LinkedinIcon />
              </a>
            )}
          </Chakra.Stack>
        </Chakra.Box>
      </Chakra.Stack>
      <Chakra.Box maxW="1373px" w="100%" m="auto">
        <Chakra.Text pt={{ base: "58px", xl: 0 }}>
          ©{new Date().getFullYear()} by Metan. Alle Rechte vorbehalten.
        </Chakra.Text>
      </Chakra.Box>
    </Chakra.Flex>
  );
};

export default Footer;
