import React from "react";
import * as Chakra from "@chakra-ui/react";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import FadeTransition from "../components/transitions/fade-transition";
import "./index.css";
import "keen-slider/keen-slider.min.css";
import { Script } from "gatsby";

const Layout = ({ children, location }) => {

  return (
    <>
      <Script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="d46757cc-fb41-4caf-97d5-7932c50e9d47" data-blockingmode="auto" type="text/javascript" />
      <Script  id="googletag_script" async src="https://www.googletagmanager.com/gtag/js?id=AW-362847176" />
      <Script id="googletag_config">{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-362847176'); gtag('config', 'UA-195134969-2');`}</Script>
      <Script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NK7C8L4');`}
      </Script>
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NK7C8L4"
height="0" width="0" style={{  display:'none', visibility:'hidden' }}></iframe></noscript>
      <Chakra.Flex minH="100vh" flexDirection="column">
        <Header />
        <FadeTransition shouldChange={location.pathname}>
          {children}
        </FadeTransition>
        <Footer />
      </Chakra.Flex>
      <Script id="linkedin_config" type="text/javascript">
          {`_linkedin_partner_id = "3096802";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
        </Script>
        <Script id="linkedin_script" type="text/javascript">
          {`(function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`}
        </Script>
        <noscript>
        <img height="1" width="1" style={{ display: 'none' }} alt="" src="https://px.ads.linkedin.com/collect/?pid=3096802&fmt=gif" />
        </noscript>
        <Script id="salesviewer_script">
          {`!(function (s, a, l, e, sv, i, ew, er) {try {(a =s[a] || s[l] || function () {throw "no_xhr";}),(sv = i = "https://salesviewer.org"),(ew = function(x){(s = new Image()), (s.src = "https://salesviewer.org/tle.gif?sva=d9E2B5B5t9r9&u="+encodeURIComponent(window.location)+"&e=" + encodeURIComponent(x))}),(l = s.SV_XHR = function (d) {return ((er = new a()),(er.onerror = function () {if (sv != i) return ew("load_err"); (sv = "https://www.salesviewer.com/t"), setTimeout(l.bind(null, d), 0);}),(er.onload = function () {(s.execScript || s.eval).call(er, er.responseText);}),er.open("POST", sv, !0),(er.withCredentials = true),er.send(d),er);}),l("h_json=" + 1 * ("JSON" in s && void 0 !== JSON.parse) + "&h_wc=1&h_event=" + 1 * ("addEventListener" in s) + "&sva=" + e);} catch (x) {ew(x)}})(window, "XDomainRequest", "XMLHttpRequest", "d9E2B5B5t9r9");`}
        </Script>
        <noscript><img src="https://salesviewer.org/d9E2B5B5t9r9.gif" style={{visibility: 'hidden'}} /></noscript>
    </>
  );
}

export default Layout
