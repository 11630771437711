import React from "react";

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.875"
      height="34.664"
      viewBox="0 0 34.875 34.664"
    >
      <path
        fill="#fff"
        d="M35.438 18a17.438 17.438 0 10-20.163 17.227V23.041h-4.43V18h4.43v-3.842c0-4.37 2.6-6.784 6.586-6.784a26.836 26.836 0 013.9.34V12h-2.2a2.52 2.52 0 00-2.841 2.723V18h4.836l-.773 5.041h-4.058v12.186A17.444 17.444 0 0035.438 18z"
        data-name="FacebookIcon awesome-facebook"
        transform="translate(-.563 -.563)"
      ></path>
    </svg>
  );
}

export default FacebookIcon;
