import React from "react";
import * as Chakra from "@chakra-ui/react";

const Heading = ({ ...props }) => {
  const fontSizeMap = {
    h1: { base: "30px", xl: "70px" },
    h2: { base: "30px", xl: "50px" },
    h3: { base: "16px", xl: "24px" },
    h4: "22px",
    h5: "16px",
  };

  const fontWeightMap = {
    h1: "800",
    h2: "800",
    h3: "600",
    h4: "300",
    h5: "600",
  };

  return (
    <Chakra.Heading
      textTransform="uppercase"
      fontWeight={fontWeightMap[props.as || "h2"]}
      fontSize={fontSizeMap[props.as || "h2"]}
      {...props}
    />
  );
};

export default Heading;
