import React from "react";
import * as Chakra from "@chakra-ui/react";
import LogoLarge from "../logo-large";
import LogoSmall from "../logo-small";
import HamburgerIcon from "../icons/hamburger-icon";
import HeaderLink from "./header-link";
import HeaderResponsiveMenu from "./header-responsive-menu";
import { useStaticQuery, graphql, Link } from "gatsby";
import useScrollPosition from "@react-hook/window-scroll";
import HeaderStar from "../../images/header-star.svg";

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        rooms {
          data {
            attributes {
              slug
              title
            }
            id
          }
        }
        industries(publicationState: LIVE) {
          data {
            attributes {
              title
            }
          }
        }
        roomCategories {
          data {
            id
            attributes {
              rooms {
                data {
                  attributes {
                    title
                    slug
                    products {
                      data {
                        id
                        attributes {
                          title
                          slug
                        }
                      }
                    }
                  }
                }
              }
              color
              title
            }
          }
        }
        services {
          data {
            attributes {
              slug
              title
            }
          }
        }
        siteSetting {
          data {
            attributes {
              katalog
              header_company_links {
                id
                text
                url
              }
              katalog_file {
                data {
                  attributes {
                    url
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
        companyInformations {
          data {
            id
            attributes {
              slug
              title
            }
          }
        }
      }
    }
  `);

  const prevScr = React.useRef(0);
  const currentScr = React.useRef(0);
  const scrollY = useScrollPosition(20);

  prevScr.current = currentScr.current;
  currentScr.current = scrollY;

  const menu = React.useMemo(() => {
    let items = data.strapi;
    let menu = [];
    menu.push({
      menu: "Unsere Stahlmöbel",
      submenu: items?.roomCategories?.data?.map((x) => {
        return {
          title: x.attributes.title,
          color: x.attributes.color,
          slug: data?.strapi?.rooms?.data.filter(
            (room) => room?.attributes?.title === x?.attributes?.title
          )[0]?.attributes?.slug,
          rooms: x.attributes.rooms.data.map((y) => {
            return {
              title: y.attributes.title,
              href: `/filterseite/produktlinien/${y.attributes.slug}`,
              count: y.attributes.products.data.length,
            };
          }),
        };
      }),
    });
    menu.push({
      menu: "Katalog",
      href:
        data?.strapi?.siteSetting?.data?.attributes?.katalog_file?.data
          ?.attributes?.localFile?.publicURL ??
        data?.strapi?.siteSetting?.data?.attributes?.katalog,
      isExternal: data?.strapi?.siteSetting?.data?.attributes?.katalog_file
        ?.data?.attributes?.localFile?.publicURL
        ? true
        : false,
    });
    menu.push({
      menu: "Service",
      submenu: items?.services?.data?.map((x) => {
        return {
          title: x.attributes.title,
          href: `/service/${x.attributes.slug}`,
        };
      }),
    });
    menu.push({
      menu: "Unternehmen",
      submenu:
        data?.strapi?.siteSetting?.data?.attributes?.header_company_links?.map(
          (x) => {
            return {
              title: x?.text,
              href: `${x?.url}`,
            };
          }
        ),
    });

    menu.push({ menu: "Blog", href: "/blog" });
    menu.push({ menu: "Merkliste", href: "/merkliste" });
    return menu;
  }, []);

  const { isOpen, onClose, onOpen } = Chakra.useDisclosure();

  return (
    <>
      <Chakra.Flex
        bg="grey.100"
        w="100%"
        position="sticky"
        top="0"
        justify="center"
        zIndex={100}
        // height="12vh"
        height={{ base: "95px", xl: "141px" }}
        shadow="base"
        transition="transform 0.3s"
        transform={
          scrollY > 300 && prevScr.current < scrollY && !isOpen
            ? "translateY(-100%)"
            : "translateY(0)"
        }
      >
        <Chakra.Flex
          justify="space-between"
          h="100%"
          //position="relative"
          align="center"
          maxWidth="1453px"
          px={{ base: "20px", xl: "40px" }}
          mx="auto"
          w="100%"
        >
          <Chakra.Flex
            as={Link}
            to="/"
            cursor="pointer"
            maxH="113px"
            height="100%"
            py="10px"
            display={{ base: "none", xl: "flex" }}
            css={{ svg: { width: "100%", height: "100%" } }}
          >
            <LogoLarge />
          </Chakra.Flex>
          <Chakra.Flex
            as={Link}
            to="/"
            cursor="pointer"
            w="59px"
            h="64px"
            display={{ base: "flex", xl: "none" }}
            css={{ svg: { width: "100%", height: "100%" } }}
            onClick={onClose}
          >
            <LogoSmall />
          </Chakra.Flex>
          <Chakra.Stack
            display={{ base: "none", xl: "flex" }}
            textTransform="uppercase"
            direction="row"
            spacing="32px"
            pt="90px"
          >
            {menu.map((item, i) => (
              <HeaderLink
                key={i}
                position={i + 2 >= menu.length ? "left" : "right"}
                {...item}
              >
                {item.menu === "Merkliste" && <HeaderStar />}
                <Chakra.Text pl={item.menu === "Merkliste" ? "8px" : "0"}>
                  {" "}
                  {item.menu}
                </Chakra.Text>
              </HeaderLink>
            ))}
          </Chakra.Stack>
          <Chakra.Flex
            cursor="pointer"
            justify="flex-end"
            w="26px"
            h="23px"
            display={{ base: "flex", xl: "none" }}
            css={{ svg: { width: "100%", height: "100%" } }}
          >
            <HamburgerIcon
              onClick={() => (isOpen ? onClose() : onOpen())}
              style={{
                transformBox: "fill-box",
                transformOrigin: "center",
                transform: `rotate(${isOpen ? "-90" : "0"}deg)`,
                transition: "transform .3s",
              }}
            />
          </Chakra.Flex>
        </Chakra.Flex>
        <HeaderResponsiveMenu menu={menu} isOpen={isOpen} onClose={onClose} />
      </Chakra.Flex>

    </>
  );
};

export default Header;
