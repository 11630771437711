import React from "react";
import * as Chakra from "@chakra-ui/react";

const Backdrop = ({ onClose, isOpen, ...props }) => {
  const topSpacing = Chakra.useBreakpointValue({ base: "95px", xl: "141px" });

  return (
    <Chakra.Flex
      onClick={() => onClose()}
      transition="opacity .3s"
      bg="rgba(0,0,0,.25)"
      zIndex={isOpen ? "499" : "-1"}
      opacity={isOpen ? "1" : "0"}
      css={{
        backdropFilter: "blur(4px)",
      }}
      pos="absolute"
      top={topSpacing}
      w="100%"
      h={`calc(100% - ${topSpacing})`}
      {...props}
    ></Chakra.Flex>
  );
};

export default Backdrop;
